const PastaIcon = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.15 23.4802C23.46 23.9102 23.37 24.5102 22.94 24.8202C22.77 24.9402 22.57 25.0002 22.38 25.0002C22.08 25.0002 21.79 24.8602 21.61 24.6102C21.55 24.5302 20.19 22.6302 19.44 19.6202C19.33 19.1902 19.24 18.7502 19.17 18.3202L21.07 18.0602C21.13 18.4202 21.21 18.7902 21.3 19.1602C21.96 21.8102 23.15 23.4702 23.16 23.4802H23.15ZM24.79 8.77019C24.87 8.20019 24.42 7.81019 23.9 7.74019L21.24 7.38019L20.73 9.55019H23.9C24.34 9.55019 24.73 9.22019 24.79 8.77019ZM23.9 14.9202H19.46L18.87 17.4102L23.89 16.7302L24.01 16.7102C24.5 16.6402 24.85 16.1902 24.78 15.7002C24.72 15.2502 24.33 14.9202 23.89 14.9202H23.9ZM24.14 11.3302L20.36 11.1002L19.82 13.4102L24.14 13.1402C24.59 13.1102 24.96 12.7502 24.99 12.2902C25.02 11.7902 24.64 11.3602 24.14 11.3302ZM19.79 5.30019C19.28 5.18019 18.77 5.49019 18.65 6.00019L15.96 17.4202C15.84 17.9302 16.15 18.4402 16.66 18.5602C16.73 18.5802 16.81 18.5902 16.88 18.5902C17.31 18.5902 17.7 18.3002 17.8 17.8602L20.49 6.44019C20.61 5.93019 20.3 5.42019 19.79 5.30019ZM10.48 7.45019C8.94 8.00019 7.72 9.01019 7.09 10.2602H3V14.1702H7.09C7.57 15.1202 8.38 15.9302 9.42 16.5002L11.63 7.15019C11.33 7.17019 10.48 7.45019 10.48 7.45019ZM14 5.30019C13.49 5.18019 12.98 5.49019 12.86 6.00019L10.17 17.4202C10.05 17.9302 10.36 18.4402 10.87 18.5602C10.94 18.5802 11.02 18.5902 11.09 18.5902C11.52 18.5902 11.91 18.3002 12.01 17.8602L14.72 6.29019C14.76 5.83019 14.46 5.41019 14 5.30019ZM16.9 5.30019C16.39 5.18019 15.88 5.49019 15.76 6.00019L13.07 17.4202C12.95 17.9302 13.26 18.4402 13.77 18.5602C13.84 18.5802 13.92 18.5902 13.99 18.5902C14.42 18.5902 14.81 18.3002 14.91 17.8602L17.62 6.29019C17.66 5.83019 17.36 5.41019 16.9 5.30019Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PastaIcon;
