import { trendingFetcher } from "@/lib/algoliaUtils";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useInView } from "react-intersection-observer";
import useSWR from "swr";

const AlgoliaTrendingDataWrapper = ({
  onlyFetchWhenInView = true,
  render,
  renderLoading,
  shouldFetch = true,
  indexName = process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME,
  responseFields = ["hits"],
  attributesToRetrieve = [
    "id",
    "uid",
    "slug",
    "uri",
    "title",
    "image",
    "sectionHandle",
    "chefs",
    "accessLevel",
    "dietaryRequirements",
    "time",
    "recipeCount",
    "postDate",
  ],
  attributesToHighlight = [],
  facetName = undefined,
  facetValue = undefined,
  maxRecommendations = 12,
}: {
  onlyFetchWhenInView?: boolean;
  render: (data: object[]) => JSX.Element;
  renderLoading?: () => JSX.Element;
  shouldFetch?: boolean;
  indexName?: string;
  responseFields?: string[];
  attributesToRetrieve?: string[];
  attributesToHighlight?: string[];
  facetName?: string;
  facetValue?: string;
  maxRecommendations?: number;
}) => {
  // Setup the in-view hook
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  // Determine whether we really should fetch, given the props
  const reallyShouldFetch =
    shouldFetch && (!onlyFetchWhenInView || (onlyFetchWhenInView && inView));

  const { data, error } = useSWR(
    reallyShouldFetch
      ? {
          indexName,
          responseFields,
          attributesToRetrieve,
          attributesToHighlight,
          facetName,
          facetValue,
          maxRecommendations,
        }
      : null,
    trendingFetcher
  );

  // Unlike a normal query, data is data?.results?.[0] instead of data?.hits
  const trendingData = data?.results?.[0];

  // Conditional rendering based on the data, error, and loading states
  let content;
  if (error) {
    content = (
      <div className={clsx("flex w-full items-center justify-center")}>
        Error in search
      </div>
    );
  } else if (!trendingData) {
    if (renderLoading) {
      content = renderLoading();
    } else {
      content = (
        <div className={clsx("flex w-full items-center justify-center p-32")}>
          Loading...
        </div>
      );
    }
  } else {
    content = render(trendingData);
  }

  // Wrap your content with the ref assigned
  return <div ref={ref}>{content}</div>;
};

export default observer(AlgoliaTrendingDataWrapper);
