const StarIcon = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.4491 11.549C24.3291 11.189 24.0091 10.929 23.6291 10.899L17.3491 10.369L14.8891 4.57902C14.7391 4.22902 14.3991 3.99902 14.0091 3.99902C13.6191 3.99902 13.279 4.22902 13.1291 4.57902L10.679 10.369L4.39905 10.899C4.01905 10.929 3.68905 11.189 3.57905 11.549C3.45905 11.909 3.57905 12.309 3.85905 12.559L8.61905 16.689L7.18905 22.829C7.09905 23.199 7.24905 23.589 7.55905 23.819C7.86905 24.049 8.28905 24.059 8.60905 23.859L13.9991 20.599L19.3891 23.859C19.5391 23.949 19.7091 23.999 19.8791 23.999C20.0791 23.999 20.2691 23.939 20.4391 23.819C20.7491 23.589 20.8891 23.209 20.8091 22.829L19.3791 16.689L24.1391 12.559C24.4291 12.309 24.5391 11.909 24.4191 11.549H24.4491Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default StarIcon;
