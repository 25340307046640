import Spinner from "@/components/Spinner";
import { useHeaderContext } from "@/contexts/HeaderContext";
import useCurrentUser from "@/hooks/useCurrentUser";
import BackgroundVideo from "@/src/components/BackgroundVideo";
import MainHeading from "@/src/components/MainHeading";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useMediaQuery } from "@react-hook/media-query";
import clsx from "clsx";
import dayjs from "dayjs";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const HomepageHero = observer(() => {
  const { setIsHeroScrolledPast } = useHeaderContext();
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: "-1px 0px 0px 0px",
  });

  // Define the media query for mobile devices
  const isMobile = useMediaQuery("(max-width: 1024px)");

  // Determine the video URL based on the device type
  const videoUrl = isMobile
    ? "https://files.mob-cdn.co.uk/videos/home_hero_07_480p.mp4"
    : "https://files.mob-cdn.co.uk/videos/home_hero_07_720p.mp4";

  useEffect(() => {
    setIsHeroScrolledPast(!inView);

    return () => {
      setIsHeroScrolledPast(true);
    };
  }, [inView, setIsHeroScrolledPast]);

  return (
    <div ref={ref} className={clsx("bg-white text-white")}>
      <div
        className={clsx("relative transform-gpu overflow-hidden bg-zinc-950")}
      >
        <div
          className={clsx(
            "relative z-10 space-y-4 px-6 py-24 sm:space-y-6 md:px-12 lg:py-24"
          )}
        >
          <_Heading />
          <_Form />
          <_Links />
        </div>
        <div className={clsx("absolute inset-0 z-0")}>
          <div
            className={clsx(
              "absolute inset-0 z-10 bg-gradient-to-b from-zinc-950/25 to-zinc-950/10"
            )}
          />
          <BackgroundVideo url={videoUrl} />
        </div>
      </div>
    </div>
  );
});

const _Heading = observer(() => {
  const { currentUser, isLoggedIn } = useCurrentUser();

  return (
    <AnimatePresence exitBeforeEnter>
      {isLoggedIn ? (
        <LoggedInHeading key="logged-in" currentUser={currentUser} />
      ) : (
        <LoggedOutHeading key="logged-out" />
      )}
    </AnimatePresence>
  );
});

const LoggedInHeading = ({ currentUser }) => {
  const currentTime = dayjs();
  const timeOfDay = currentTime.hour() >= 12 ? "tonight" : "today";

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, delay: 0, ease: "easeOut" }}
      className={clsx("transform-gpu will-change-auto")}
    >
      <MainHeading>
        Hi, {currentUser?.firstName}. What would you like to cook {timeOfDay}?
      </MainHeading>
    </motion.div>
  );
};

const LoggedOutHeading = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3, delay: 0, ease: "easeOut" }}
      className={clsx("transform-gpu will-change-auto")}
    >
      <MainHeading>
        Never make a<br />
        bad meal
      </MainHeading>
    </motion.div>
  );
};

const _Form = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const searchQuery = formData.get("query");

    if (searchQuery) {
      setIsLoading(true);
      const encodedQuery = encodeURIComponent(searchQuery.toString());
      router.push(`/search?Entries%5Bquery%5D=${encodedQuery}`);
    }
  };

  return (
    <form className={clsx("relative w-full max-w-3xl")} onSubmit={handleSubmit}>
      <input
        type="text"
        name="query"
        placeholder="Search by dish, ingredient or cuisine"
        className={clsx(
          "h-component-lg block w-full rounded-full bg-white px-5 font-body text-sm text-zinc-950 sm:h-12 sm:px-6 sm:text-base md:h-12 md:px-8 lg:h-16 lg:text-lg"
        )}
        disabled={isLoading}
      />
      <button
        type="submit"
        className={clsx(
          "w-component-lg h-component-lg absolute right-0 top-0 rounded-full sm:size-12 lg:size-16"
        )}
        disabled={isLoading}
      >
        <div
          className={clsx(
            "absolute inset-1 flex items-center justify-center rounded-full bg-zinc-950 md:inset-1.5",
            { "opacity-70": isLoading }
          )}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <ArrowRightIcon className={clsx("size-4 sm:size-5 lg:size-6")} />
          )}
        </div>
      </button>
    </form>
  );
};

const _Links = () => {
  return (
    <div className={clsx("flex items-center gap-4 font-body text-sm")}>
      <_Link href="/search?Entries%5Bmenu%5D%5Bsection.name%5D=Recipes">
        All recipes
      </_Link>
      <_Link href="/meal-plans">All meal plans</_Link>
    </div>
  );
};

const _Link = ({ href, children }) => {
  return (
    <Link href={href}>
      <a
        className={clsx(
          "h-component flex items-center justify-center rounded-full bg-white/20 px-5 backdrop-blur-lg transition ease-out hover:bg-white/30"
        )}
      >
        {children}
      </a>
    </Link>
  );
};

export default HomepageHero;
