const BoltIcon = (props) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.0699 12.6741C22.9906 12.4574 22.7845 12.3095 22.552 12.3095H16.322L17.8808 4.15604C17.9231 3.92354 17.8174 3.68575 17.6061 3.5695C17.3947 3.45325 17.1358 3.48495 16.9614 3.64876L5.07735 14.7243C4.91354 14.8776 4.85542 15.1206 4.93997 15.332C5.02451 15.5487 5.22531 15.686 5.45253 15.686H11.5927L9.02989 23.7761C8.95592 24.0086 9.04575 24.2675 9.24654 24.4049C9.33638 24.4683 9.44206 24.5 9.55303 24.5C9.68513 24.5 9.81195 24.4524 9.90706 24.3679L22.9061 13.287C23.0804 13.1391 23.1438 12.8907 23.0646 12.6741H23.0699Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BoltIcon;
